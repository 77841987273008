<template lang="pug">
    v-btn(
        :loading="busy"
        color="primary"
        @click="exportCompanyEmployees")
        v-icon(left) cloud_download
        | {{ $t('actions.export') }}
</template>

<script>
import ExportUsers from '@/services/Users/ExportUsers'

export default {
    props: {
        selectedFilters: Object,
        selectedItems: Array,
    },
    data() {
        return {
            busy: false,
        }
    },
    methods: {
        exportCompanyEmployees() {
            this.busy = true
            ExportUsers({
                ...{ id: this.selectedItems },
                ...this.selectedFilters,
            }).finally(() => {
                this.busy = false
            })
        },
    },
}
</script>
