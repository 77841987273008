<script>
import PageTitle from '@/components/layout/components/pageTitle'
import routeType from '@/router/routeType'
import UsersTable from '@/components/pages/users/Table'
import CreateButton from '@/components/form/Actions/CreateButton'
import Export from '@/pages/users/data/Export'
import AuthMixin from '@/components/mixins/AuthMixin'

export default {
    components: {
        PageTitle,
        UsersTable,
        CreateButton,
        Export,
    },
    mixins: [AuthMixin],
    data() {
        return {
            routeType,
            selectedFilters: {},
            selectedItems: [],
        }
    },
}
</script>

<template lang="pug">
    div
        page-title {{ $t('users.list_page_title') }}
        users-table(ref="table" :selected-filters.sync="selectedFilters" :selected-items.sync="selectedItems")
            template(v-slot:actions)
                create-button(:route-name="routeType.USER_CREATE" tabbed v-if="canCreateUsers")
        portal(to="footer")
            export.mr-2(v-if="isSuperAdmin" :selected-filters="selectedFilters" :selected-items="selectedItems")

</template>
